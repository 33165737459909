<template>
  <div class="balance-card">
    <div>
      <div class="bb-intro-top">
        <i class="fas fa-donate"></i>
        <b class="b-icon-title"> {{ $t("toefl.Your T-Coin Balance") }}</b>
      </div>
      <div class="b-info">
        <p class="info-tip">{{ $t("toefl.Balance") }}</p>
        <h3 class="balance-price">{{ point }}</h3>
        <span class="balance-name" v-if="point > 0">
          {{ point > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    point: {
      type: Number,
      default: null
    }
  }
};
</script>

<style scoped>
.b-info .balance-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 18px;
  /* color: #99a2aa; */
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
@media screen and (max-width: 400px) {
  .b-info {
    padding: 0 15px;
  }
}
</style>
