import axios from "@/common/interceptors.service";
import ApiService from "@/common/api.service";

export default {
  getPlans(data) {
    return ApiService.query("/plans", data);
  },
  getPaymentLink(data) {
    return ApiService.post("coins/payment-link", data);
  },
  getGreenPaymentLink(data) {
    return ApiService.post("ecpay/coins/payment-link", data);
  },
  // unlockUserExam(id, data) {
  //   return ApiService.post(`coins/plans/${id}/expend`, data);
  // },
  unlockUserExam(id, data) {
    return ApiService.post(`exams/${id}/obtain`, data);
  },
  unlockProUserExam(id, data) {
    return ApiService.post(`pro/exams/${id}/obtain`, data);
  },
  unLockAdminExam(id, data) {
    return ApiService.post(`admin/exams/${id}/obtain`, data);
  },
  unLockClassExam(id, data) {
    return ApiService.post(`session-class-exams/${id}/obtain`, data);
  },
  getTransactions(user, data) {
    return ApiService.query(`users/${user}/coin-transactions`, data);
  },
  getReferralCodes() {
    return ApiService.post("referral-codes");
  }
};
